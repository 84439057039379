import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import AddNew from "../pages/AddNew/AddNew";
import Budget from "../pages/Budget/Budget";
// import Dashboard from "../pages/Dashboard/Dashboard";
import EditBudget from "../pages/EditBudget/EditBudget";

const ContentLayout = () => {
  return (
    <div className="content-wrapper">
      <Navbar />
      <div className="page-wrapper">
        <Routes>
          <Route path="/budget/:id" element={<Budget />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/edit/:id" element={<EditBudget />} />
          <Route path="/new" element={<AddNew />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="*" element={<Navigate to="/budget" />} />
        </Routes>
      </div>
    </div>
  );
};

export default ContentLayout;
