import { logout } from './../../api/firestore';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { signIn } from "../../api/firestore";

export const signInUser = createAsyncThunk< any, object, { rejectValue: any  }>(
    "auth/signInUser",

    async (data:any, { rejectWithValue }) => {
        try {
            const signInData = await signIn(data.email, data.password);
            return signInData;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const logoutUser = createAsyncThunk<any, undefined, { rejectValue: any}>(
    "auth/logout",
    async (data:any, { rejectWithValue }) => {
        try {
            console.log(data);
            await logout();
            return true;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
