import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "./Modal.scss";

const Modal = (props: any) => {
  return (
    <div className={`modal-overlay ${props.isOpen ? "modal-open" : ""}`}>
      <div className="modal-wrapper">
        <div className="modal-close">
            <FontAwesomeIcon onClick={() => props.closeClicked()} icon={faXmark} />
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
