import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import addSlice from './slices/Add';
import authSlice  from './slices/Auth';
import budgetSlice from './slices/Budget';
import dashboardSlice from './slices/Dashboard';
import editSlice from './slices/EditBudget';


const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

export const store = configureStore({
  reducer: {
    auth: authSlice,
    add: addSlice,
    dashboard: dashboardSlice,
    budget: budgetSlice,
    edit: editSlice
  },
  middleware: customizedMiddleware
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
