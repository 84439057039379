import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import './Dropdown.scss';

const Dropdown = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const checkIfClickedOutside = (e: any) => {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu
        if (isOpen && !props.multiple && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
          console.log('setting is open to false');
          
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", checkIfClickedOutside);

      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [isOpen]);

  const selectOption = (option: any) => {
    if (props.multiple) {
      props.setValue(option, true);
    } else {
      props.setValue(option);
    }
    if (!props.multiple) {
      setIsOpen(!isOpen);
    }
  };

  const checkIfValue = (val:any, option:any) => {
    if (Array.isArray(val)) {
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        if (element == option.label) {
          return 'selected-option'
        }
      }
      return '';
    }
    else {
      if (val == option.label) {
        return "selected-option";
      }
      else {
        return '';
      }
    }
  }

  const getDropdownLabel = (val:any) => {    
    if (props.multiple) {
      let array:string[] = [];
      for (let i = 0; i < props.options.length; i++) {
        const element = props.options[i];
        for (let j = 0; j < val.length; j++) {
          const selectedVal = val[j];
          if (element.value === selectedVal) {
            array.push(element.label);
          }
          
        }
      }
      return array.toString();
    } else {
      for (let i = 0; i < props.options.length; i++) {
        const element = props.options[i];
        console.log("VAL", val);
        console.log('ELEMENT', element);
        
        if (element.value == val) {
          return element.label;
        }
      }
      return false;
    }
  }

  return (
    <div
      ref={dropdownRef}
      className="dropdown-wrapper"
      style={{
        flexDirection: props.labelPosition === "top" ? "column" : "row",
      }}
    >
      {props.label ? <p className="dropdown-label">{props.label}</p> : ""}
      <div className="dropdown-input" onClick={() => setIsOpen(!isOpen)}>
        <p className="dropdown-input-text">
          {getDropdownLabel(props.value)
            ? getDropdownLabel(props.value)
            : props.default}
        </p>
        {isOpen ? (
          <FontAwesomeIcon
            className="dropdown-input-caret"
            icon={faAngleUp}
          />
        ) : (
          <FontAwesomeIcon
            className="dropdown-input-caret"
            icon={faAngleDown}
          />
        )}
      </div>
      <div
        className={`dropdown-options ${isOpen ? "dropdown-options-open" : ""}`}
      >
        {props.options.map((option: any) => {
          return (
            <p
              className={`dropdown-option ${ checkIfValue(props.value, option)}`}
              onClick={() => selectOption(option.value)}
            >
              {option.label}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
