import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Auth/Login/Login";

const AuthLayout = () => {
  return (
    <div className="layout-wrapper">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

export default AuthLayout;