import "./Navbar.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutUser } from "../../store/thunks/Auth";
import { useEffect, useState } from "react";
import { BudgetObject } from "../../utils/types";

const Navbar = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const budget = useAppSelector((state) => state.budget.budget);
  const favorite = useAppSelector((state) => state.dashboard.budgets as BudgetObject[]);

  const [displayedBudget, setDisplayedBudget] = useState({} as BudgetObject);
  
  const logout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (id && Object(budget).keys !== 0) {
      setDisplayedBudget(budget);
    }
  }, [budget]);

  useEffect(() => {
    if (!id && favorite.length > 0) {      
      setDisplayedBudget(favorite[0]);
    }
  }, [favorite])
  
  
  const formatTitle = () => {
    if (location.pathname.includes('new')) {
      return 'Add Budget';
    }
    else if (location.pathname.includes('dashboard')) {
      return 'Dashboard';
    }
    else if (location.pathname.includes('edit')) {
      return 'Edit Budget';
    }
    else if (location.pathname.includes('/budget')) {
      return displayedBudget.title;
    }
  };

  return (
    <div className="navbar-wrapper">
      <h1 className="navbar-title">{formatTitle()}</h1>
      {/* {!location.pathname.includes("new") ? (
        <div
          className="navbar-element add-new-budget"
          onClick={() => navigate("/new")}
        >
          <p>New budget</p>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      ) : (
        ""
      )} */}
      <div className="navbar-btns">
        {/* <div className="navbar-element home-btn" onClick={() => navigate("/")}>
          Home
        </div> */}
        {location.pathname.includes("edit") ||
        location.pathname.includes("new") ? (
          ""
        ) : (
          <div
            className="navbar-element home-btn"
            onClick={() => navigate(`/edit/${displayedBudget.id}`)}
          >
            Edit Budget
          </div>
        )}
        <div onClick={() => logout()} className="logout-btn">
          Logout
        </div>
      </div>
    </div>
  );
};

export default Navbar;
