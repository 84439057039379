export const pieColor = ["#4E00FF", "#FF0031", "#B1FF00", "#00FFCE", "#FF00F6", "#FF8900", "#00FF09", "#0076FF", "#8508F7", "#F70E08", "#7AF708", "#08F1F7", "#8801FE", "#FE0A01", "#77FE01", "#01F5FE", "#C37FFE", "#FE847F", "#BAFE7F", "#7FFAFE"];

export const pieChartColors = [
    "#FF6384", // pink
    "#36A2EB", // blue
    "#FFCE56", // yellow
    "#4BC0C0", // turquoise
    "#9966FF", // purple
    "#FF9F40", // orange
    "#B34D4D", // red
    "#8B88FF", // lavender
    "#67B7D1", // light blue
    "#C0C0C0"  // gray
];

export const dullerColors = ['#043b5c', '#b2de27', '#cf2f74', '#d2c2d1', '#284387', '#524eb7', '#efcfe3', '#a3a0a9', '#4a15ad', '#ddd4b3', '#bae4e5', '#ede7e1', '#fffc7f', '#fffc7f'];

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

export const quarterlyMonths = ["March", "June", "September", "December"];

export const frequencyOptions = [{ label: "Weekly", value: 'weekly' }, { label: "Bi-Monthly", value: 'bimonthly' }, { label: "Monthly", value: 'monthly' }, { label: "Annually", value: 'annually' }, { label: "Quarterly", value: 'quarterly' }];

export const categoryOptions = [{ label: "Utilities", value: 'utilities' }, { label: "Food & Drink", value: 'food_and_drink' }, { label: "Housing", value: 'housing' }, { label: "Auto", value: 'auto' }, { label: "Entertainment", value: 'entertainment' }, { label: "Other", value: 'other' }];

export const monthlyOptions = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }, { label: '6', value: 6 }, { label: '7', value: 7 }, { label: '8', value: 8 }, { label: '9', value: 9 }, { label: '10', value: 10 }, { label: '11', value: 11 }, { label: '12', value: 12 }, { label: '13', value: 13 }, { label: '14', value: 14 }, { label: '15', value: 15 }, { label: '16', value: 16 }, { label: '17', value: 17 }, { label: '18', value: 18 }, { label: '19', value: 19 }, { label: '20', value: 20 }, { label: '21', value: 21 }, { label: '22', value: 22 }, { label: '23', value: 23 }, { label: '24', value: 24 }, { label: '25', value: 25 }, { label: '26', value: 26 }, { label: '27', value: 27 }, { label: '28', value: 28 }, { label: '29', value: 29 }, { label: '30', value: 30 }, { label: '31', value: 31 }, { label: 'Last', value: 'last' }];

export const daysOfWeek = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
];

export const monthsOfYear = [
    { label: "January", value: '1' },
    { label: "February", value: '2' },
    { label: "March", value: '3' },
    { label: "April", value: '4' },
    { label: "May", value: '5' },
    { label: "June", value: '6' },
    { label: "July", value: '7' },
    { label: "August", value: '8' },
    { label: "September", value: '9' },
    { label: "October", value: '10' },
    { label: "November", value: '11' },
    { label: "December", value: '12' },
];