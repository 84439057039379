import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getSingleBudget } from '../../store/thunks/Budget';
import { getBudgets } from '../../store/thunks/Dashboard';
import './Budget.scss';
import Loader from '../../components/Loader/Loaders';
import PieGraph from '../../components/PieChart/Piechart';
import CalendarComponent from '../../components/Calendar/Calendar';
import {
  formatCardStats,
  formatPieData,
  getCalendarDates,
  makeBudgetAnnual,
  formatUpcoming,
  formattedAmount,
  sortByValue
} from "../../utils/utils";
import { BudgetObject } from '../../utils/types';
import {
  monthNames,
  pieChartColors,
} from "../../utils/constants";
import Modal from '../../components/Modal/Modal';

const Budget = () => {

    const { id } = useParams();
    const budget = useAppSelector((state) => state.budget.budget as BudgetObject);
    const favorite = useAppSelector((state) => state.dashboard.budgets as BudgetObject[]);
    const addBudget = useAppSelector((state) => state.dashboard.addBudget);
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [displayedBudget, setDisplayedBudget] = useState({} as any);
    const [dateFilter, setDateFilter] = useState('Monthly'); 
    const [pieData, setPieData] = useState([] as any[]);
    const [cardStats, setCardStats] = useState({} as any);
    const [calendarData, setCalendarData] = useState([] as any[]);
    const [modalDateData, setModalDateData] = useState([] as any[]);
    const [modalDate, setModalDate] = useState('');
    const [modalDateOpen, setModalDateOpen] = useState(false);
    const [annualVersion, setAnnualVersion] = useState({} as any);
    const [upcoming, setUpcoming] = useState<any[] | undefined[]>([]);

    const showDayPlan = (day:Date) => {
      let dayPlan: any[] = [];
      for (let i = 0; i < calendarData.length; i++) {
        const element = calendarData[i];
        if (new Date(element.dueDate).toLocaleDateString('en-CA') === new Date(day).toLocaleDateString('en-CA')) {
          dayPlan.push(element);
        }
      };
      
      if (dayPlan.length > 0) {

        setModalDate(
          [day.getMonth() + 1, day.getDate()].join("/")
        );
        setModalDateOpen(true);
        setModalDateData(dayPlan)
        return dayPlan;
      }
    };

    useEffect(() => {
      console.log('DIsplayed Budget', displayedBudget);
      
    }, [displayedBudget])
    
    
    useEffect(() => {
      if (addBudget) {
        navigate('/new');
      }
    }, [addBudget])
    
    useEffect(() => {
      if (id) {
        dispatch(getSingleBudget(id));
      } else {
        dispatch(
          getBudgets({
            filters: [
              { field: "user_id", operator: "==", value: user.uid },
              { field: "favorite", operator: "==", value: true },
            ],
          })
        );
      }
    }, []);

    useEffect(() => {
      if (Object.keys(budget).length !== 0) {
        setDisplayedBudget(budget);
      }
    }, [budget]);

    useEffect(() => {
      if (favorite.length > 0) {        
          setDisplayedBudget(favorite[0]);
      }
    }, [favorite]);

    useEffect(() => {
      if (Object.keys(displayedBudget).length !== 0) {
        setAnnualVersion(makeBudgetAnnual(displayedBudget));
        setPieData(formatPieData(displayedBudget));
        setCardStats(formatCardStats(displayedBudget));
        setCalendarData(getCalendarDates(displayedBudget));
        setUpcoming(formatUpcoming([displayedBudget]));
      }
    }, [displayedBudget])
    
    useEffect(() => {
      if (dateFilter === 'Annually') {
        setPieData(formatPieData(annualVersion));
        setCardStats(formatCardStats(annualVersion));
        setCalendarData(getCalendarDates(annualVersion));
      } else if (dateFilter === 'Monthly' && Object.keys(displayedBudget).length !== 0) {
        setPieData(formatPieData(displayedBudget));
        setCardStats(formatCardStats(displayedBudget));
        setCalendarData(getCalendarDates(displayedBudget));
      }
    }, [dateFilter]);
    
    
    return (
      <>
        {Object.keys(displayedBudget).length !== 0 &&
        Object.keys(cardStats).length !== 0 ? (
          <div className="budget-wrapper">
            {/* <div className="budget-header">
              <div className="budget-header-btns">
                <ToggleSwitch
                  value={dateFilter}
                  option1="Monthly"
                  option2="Annually"
                  label={`budget-frequency`}
                  onChange={(val: string) =>
                    setDateFilter(
                      dateFilter === "Monthly" ? "Annually" : "Monthly"
                    )
                  }
                />
                <div
                  className="budget-edit-btn btn"
                  onClick={() => navigate(`/edit/${displayedBudget.id}`)}
                >
                  Edit Budget
                </div>
              </div>
            </div> */}
            <div className="budget-body">
              <div className="budget-upcoming">
                <h1>Upcoming</h1>
                <div className="budget-upcoming-calendar">
                  { upcoming.length > 0 ? upcoming.map((expense) => {
                    if (expense) {
                      return (
                        <div className="budget-upcoming-date-wrapper">
                          <div className="budget-upcoming-date-day">
                            <div className="budget-upcoming-date-day-top">
                              <p>{expense.dueDate.getDate()}</p>
                            </div>
                            <div className="budget-upcoming-date-day-bottom">
                              <p>
                                {monthNames[expense.dueDate.getMonth()].slice(0, 3)}
                              </p>
                            </div>
                          </div>
                          <div className="budget-upcoming-date-expenses">
                            {expense.group.map((grouped_expense: any) => {
                              return (
                                <div className="budget-upcoming-date-expense">
                                  <p>{grouped_expense.label}</p>
                                  <p
                                    className={
                                      grouped_expense.type === "expense"
                                        ? "upcoming-expense-item"
                                        : "upcoming-income-item"
                                    }
                                  >
                                    {grouped_expense.type === "expense"
                                      ? "-"
                                      : "+"}
                                    ${grouped_expense.amount}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  }) : <div className='no-upcoming'>No upcoming events</div>}
                </div>
              </div>
              <div className="budget-calendar">
                <p>Bill Calendar</p>
                <CalendarComponent
                  showDayPlan={showDayPlan}
                  dates={calendarData}
                  showNavigation={false}
                />
              </div>
              <div className="budget-cards">
                <div className="budget-card">
                  <p>
                    {dateFilter === "Monthly" ? "Monthly" : "Annual"} Income
                  </p>
                  <h2 className="budget-card-green">+${cardStats.income}</h2>
                </div>
                <div className="budget-card">
                  <p>
                    {dateFilter === "Monthly" ? "Monthly" : "Annual"} Spending
                  </p>
                  <h2 className="budget-card-red">-${cardStats.expenses}</h2>
                </div>
                <div className="budget-card">
                  <p>Biggest Expense</p>
                  <h2 className="budget-card-blue">{cardStats.max.label}</h2>
                </div>
                <div className="budget-card">
                  <p>Smallest Expense</p>
                  <h2 className="budget-card-blue">{cardStats.min.label}</h2>
                </div>
              </div>
              <div className='budget-graph-wrapper'>
                <div className="budget-graph">
                  <PieGraph data={pieData} />
                </div>
                <div className="budget-legend">
                  {pieData.map((dataPoint: any, index: number) => {
                    return (
                      <div className="budget-legend-item">
                        <div
                          className="budget-legend-color"
                          style={{ backgroundColor: pieChartColors[index] }}
                        ></div>
                        <p className="budget-legend-name">{dataPoint.name}</p>
                        <p className="budget-legend-value">{dataPoint.value}%</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="budget-elements">
                <div className="budget-expenses-list">
                  <h2>Expenses</h2>
                  <span></span>
                  {sortByValue(displayedBudget.expenses).map((single_exp: any) => {
                    return (
                      <div className='budget-list-item'>
                        <p>{single_exp.label}</p>
                        <p>{single_exp.amount}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="budget-income-list">
                  <h2>Income</h2>
                  <span></span>      
                  {sortByValue(displayedBudget.income).map((single_inc: any) => {
                    return (
                      <div className='budget-list-item'>
                        <p>{single_inc.label}</p>
                        <p>{single_inc.amount}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Modal
              isOpen={modalDateOpen}
              closeClicked={() => setModalDateOpen(!modalDateOpen)}
            >
              <div className="date-selected-wrapper">
                <div className="date-selected">
                  <h1>{modalDate}</h1>
                </div>
                <div className="date-data-wrapper">
                  {modalDateData.map((data: any) => {
                    return (
                      <div className="date-data">
                        {data.type === "Income" ? (
                          <div className="date-data-income">
                            <p>{data.label}</p>
                            <span className="date-data-spacer"></span>
                            <h2>${data.amount}</h2>
                          </div>
                        ) : (
                          <div className="date-data-expense">
                            <p>{data.label}</p>
                            <span className="date-data-spacer"></span>
                            <h2>${data.amount}</h2>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <Loader height={"82vh"} width={"100%"} />
        )}
      </>
    );
};

export default Budget;