import { queryBuilder } from './queryBuilder';
import { auth, db } from "../FirebaseConfig";
import { collection, addDoc, deleteDoc, setDoc, doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { filterItem, sortObj } from "../utils/types";

export const signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // ...
            return user;
        })
        .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            throw error;
        });
};

export const logout = async () => {
    return signOut(auth).then(() => {
        // Sign-out successful.        
        return true;
    }).catch((error) => {
        // An error happened.
        throw error;
    });
};

export const createDoc = async (type: string, data: any) => {
    try {
        const docRef = collection(db, type);
        const createdDoc = await addDoc(docRef, data);
        return createdDoc;
    } catch (error) {
        throw error;
    }
};

export const updateDoc = async (type: string, data: any) => {
    try {
        const docRef = doc(db, type, data.id);
        await setDoc(docRef, data);
        return true;
    } catch (error) {
        throw error;
    }
};

export const removeDoc = async (type: string, id: string) => {
    try {
        const docRef = doc(db, type, id);
        await deleteDoc(docRef);
        return true;
    } catch (error) {
        throw error;
    }
};

export const getDocs = async (type: string, filters?: filterItem[], sort?: sortObj, limitData?: number) => {
    try {
        const docs = await queryBuilder(type, filters, sort, limitData);
        return docs;
    } catch (error) {
        throw error;
    }
};

export const getSingleDoc = async (type: string, id:any) => {
    const docRef = doc(db, type, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return { id: docSnap.id, ...docSnap.data()};
    } else {
        throw new Error('No such document');
    }
};