import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { signInUser } from "../../../store/thunks/Auth";
import "./Login.scss";

const Login = (props: any) => {
  const dispatch = useAppDispatch();

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const submitForm = () => {
    if (userEmail && userPassword) {
      dispatch(signInUser({ email: userEmail, password: userPassword }));
    }
  };

  return (
    <div className="login-wrapper">
      <h1 className="login-wrapper-title">Budgets By Me</h1>
      <div className="login-form">
        <h2>Sign In</h2>
        <div className="login-form-fieldset">
          <input
            placeholder="Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            type={"email"}
          />
          <input
            placeholder="Password"
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
            type={"password"}
          />
        </div>
        <div className="submit-btn" onClick={() => submitForm()}>
          <p>Submit</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
