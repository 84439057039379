import './Input.scss';

const Input = (props: any) => {
  return (
    <div className="input-wrapper" style={{ flexDirection: props.labelPosition === 'top' ? 'column' : 'row' }}>
      {props.label ? <p className="input-label">{props.label}</p> : ""}
      <input
        defaultValue={props.defaultValue}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder ? props.placeholder : ""}
        onChange={(e) => props.onChange(e.target.value)}
        checked={props.checked ? props.checked : ''}
      />
    </div>
  );
};

export default Input;
