import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { auth } from "../FirebaseConfig";
import { resetAdd } from "../store/slices/Add";
import { foundUser } from "../store/slices/Auth";
import { resetBudget } from "../store/slices/Budget";
import { resetDashboard } from "../store/slices/Dashboard";
import { resetEdit } from "../store/slices/EditBudget";
import AuthLayout from "./AuthLayout";
import ContentLayout from "./ContentLayout";

const Layout = () => {

    const authenticated = useAppSelector((state) => state.auth.authenticated);
    const dispatch = useAppDispatch();

    useEffect(() => {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.
          dispatch(foundUser(user));
        }
      });
    }, []);

    useEffect(() => {
      if (!authenticated) {
        dispatch(resetAdd());
        dispatch(resetBudget());
        dispatch(resetDashboard());
        dispatch(resetEdit());
      }
    }, [authenticated])
    
    

    return (
        <div className="layout-wrapper">
            { authenticated ? <ContentLayout/> : <AuthLayout /> }
        </div>
    );
};

export default Layout;