import { createBudget } from './../thunks/Add';
import { createSlice } from '@reduxjs/toolkit';

export interface AddState {
    errors: string | null[]
    loading: boolean,
    success: boolean
};

const initialState: AddState = {
    errors: [],
    loading: false,
    success: false
};

export const addSlice = createSlice({
    name: 'add',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setSuccess(state, action) {
            state.success = action.payload;
        },
        resetAdd: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(createBudget.pending, (state) => {
            state.loading = true;
        })
        .addCase(createBudget.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
        })
        .addCase(createBudget.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
    }
});

export const { setLoading, setSuccess, resetAdd } = addSlice.actions;

export default addSlice.reducer;