import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import Input from "../Input/Input";
import "./BudgetElement.scss";
import Dropdown from "../Dropdown/Dropdown";
import { frequencyOptions, categoryOptions, monthlyOptions, daysOfWeek, monthsOfYear } from "../../utils/constants";

const BudgetElement = (props: any) => {

  return (
    <div className="budget-form-fieldset">
      {props.type === "Expense" ? (
        <Dropdown
          value={props.category}
          default="Select an option"
          label="Category"
          setValue={(val: any) => props.onChange("category", val)}
          options={categoryOptions}
          labelPosition={props.labelPosition}
        />
      ) : (
        // <Input
        //   type="text"
        //   value={props.category}
        //   label={`Category`}
        //   labelPosition={props.labelPosition}
        //   onChange={(val: string) => props.onChange("category", val)}
        // />
        ""
      )}
      <Input
        type="text"
        value={props.label}
        label={`Label`}
        labelPosition={props.labelPosition}
        onChange={(val: string) => props.onChange("label", val)}
      />
      <Input
        type="number"
        value={props.amount}
        label={`Amount`}
        labelPosition={props.labelPosition}
        onChange={(val: string) => props.onChange("amount", val)}
      />
      <Dropdown
        value={props.frequency}
        default="Select an option"
        label="Frequency"
        setValue={(val: any) => props.onChange("frequency", val)}
        options={frequencyOptions}
        labelPosition={props.labelPosition}
      />
      {/* <div className="budget-form-fieldset-toggle">
        <p className="budget-form-fieldset-toggle-label">Frequency of item</p>
        
        <ToggleSwitch
          value={props.frequency}
          option1="Monthly"
          option2="Annual"
          label="Frequency of expense"
          onChange={(val: string) => props.onChange("frequency", val)}
        />
      </div> */}
      {props.frequency == "monthly" && (
        <Dropdown
          value={props.dueDate}
          default="Select a day"
          label="Due Date"
          setValue={(val: any) => props.onChange("dueDate", val)}
          options={monthlyOptions}
          labelPosition={props.labelPosition}
        />
      )}
      {props.frequency == "quarterly" && (
        <Dropdown
          value={props.dueDate}
          default="Select a day"
          label="Due Date"
          setValue={(val: any) => props.onChange("dueDate", val)}
          options={monthlyOptions}
          labelPosition={props.labelPosition}
        />
      )}
      {props.frequency == "bimonthly" && (
        <Dropdown
          value={props.dueDate}
          default="Select 2 days"
          label="Due Date"
          multiple={true}
          setValue={(val: any) => props.onChange("dueDate", val, 2)}
          options={monthlyOptions}
          labelPosition={props.labelPosition}
        />
      )}
      {props.frequency == "weekly" && (
        <Dropdown
          value={props.dueDate}
          default="Select a day"
          label="Due Date"
          setValue={(val: any) => props.onChange("dueDate", val)}
          options={daysOfWeek}
          labelPosition={props.labelPosition}
        />
      )}
      {props.frequency == "annually" && (
        <div className="annual-options">
          <Dropdown
            value={
              Array.isArray(props.dueDate) ? props.dueDate[0] : props.dueDate
            }
            default="Select a month"
            label="Due Date - Month"
            setValue={(val: any) => props.onChange("dueDate", val)}
            options={monthsOfYear}
            labelPosition={props.labelPosition}
          />
          <Dropdown
            value={
              Array.isArray(props.dueDate) ? props.dueDate[1] : props.dueDate
            }
            default="Select a day"
            label="Day"
            setValue={(val: any) => props.onChange("dueDate", val)}
            options={monthlyOptions}
            labelPosition={props.labelPosition}
          />
        </div>
      )}
      <div className="trash-icon">
        <FontAwesomeIcon onClick={() => props.onDelete()} icon={faTrashCan} />
      </div>
    </div>
  );
};

export default BudgetElement;
