import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calendar.scss";

const CalendarComponent = (props: any) => {

  return (
    <div className="calendar-wrapper">
      <Calendar
        calendarType="US"
        showNavigation={props.showNavigation}
        onClickDay={(e) => props.showDayPlan(e)}
        tileClassName={({ date }) => {
            let exists = false;
          for (let i = 0; i < props.dates.length; i++) {
            const element = props.dates[i];
            if (new Date(element.dueDate).toLocaleDateString('en-CA') === new Date(date).toLocaleDateString('en-CA')) {
                exists = true;
                // console.log('found date', date);
            }
          }
          if (exists) {
            return 'highlight';
          } else {
            return null;
          }
        }}
      />
    </div>
  );
};
export default CalendarComponent;
