import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSingleDoc } from "../../api/firestore";

export const getSingleBudget = createAsyncThunk<any, string, { rejectValue: any }>(
    "budget/getSingleBudget",

    async (data: any, { rejectWithValue }) => {
        console.log('in getBudgets data', data);

        try {
            const doc = await getSingleDoc('budget', data);
            console.log('doc', doc);
            return doc;
        } catch (error) {
            console.log('getBudgets ERROR', error);

            return rejectWithValue(error);
        }
    }
);

