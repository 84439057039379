import { BudgetObject } from './../../utils/types';
import { getBudgets } from './../thunks/Dashboard';
import { createSlice } from '@reduxjs/toolkit';

export interface DashboardState {
    errors: string | null[]
    loading: boolean,
    budgets: BudgetObject[] | undefined [],
    addBudget: Boolean
};

const initialState: DashboardState = {
    errors: [],
    loading: false,
    budgets: [],
    addBudget: false
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearAddBudget(state) {
            state.addBudget = false;
        },
        resetDashboard: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(getBudgets.pending, (state) => {
            state.loading = true;
        })
        .addCase(getBudgets.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.length > 0) {
                state.budgets = action.payload;
            } else {
                state.addBudget = true;
            }
        })
        .addCase(getBudgets.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
    }
});

export const { clearAddBudget, resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;