import { signInUser, logoutUser } from './../thunks/Auth';
import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
    authenticated: boolean,
    user: any,
    authenticating: boolean,
    errors: string | null[]
};

const initialState: AuthState = {
    authenticated: false,
    user: {},
    authenticating: false,
    errors: []
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated(state, action) {
            state.authenticated = action.payload;
        },
        foundUser(state, action) {
            state.authenticated = true;
            state.user = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(signInUser.pending, (state) => {
            state.authenticating = true;
        })
        .addCase(signInUser.fulfilled, (state,action) => {
            state.authenticating = false;
            state.authenticated = true;
            state.user = action.payload;
        })
        .addCase(signInUser.rejected, (state,action) => {
            state.authenticating = false;
            state.errors = action.payload;
        })
        .addCase(logoutUser.fulfilled, (state) => {
            state.authenticated = false;
            state.user = { };
            state.authenticating = false;
            state.errors = [];
        })
        .addCase(logoutUser.rejected, (state,action) => {
            state.errors = action.payload;
        })
    }
});

export const { setAuthenticated, foundUser } = authSlice.actions;

export default authSlice.reducer;