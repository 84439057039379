import { getSingleBudget } from './../thunks/Budget';
import { createSlice } from '@reduxjs/toolkit';

export interface BudgetState {
    errors: string | null[]
    loading: boolean,
    budget: any
};

const initialState: BudgetState = {
    errors: [],
    loading: false,
    budget: {}
};

export const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        resetBudget: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(getSingleBudget.pending, (state) => {
            state.loading = true;
        })
            .addCase(getSingleBudget.fulfilled, (state, action) => {
                state.loading = false;
                state.budget = action.payload;
            })
            .addCase(getSingleBudget.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
    }
});

export const { resetBudget } = budgetSlice.actions;

export default budgetSlice.reducer;