import loader from '../../assets/images/loader.gif';
import './Loader.scss';

const Loader = (props:any) => {
    return(
        <div style={{width: props.width ? props.width : '50px', height: props.height ? props.height : '50px'}} className="loader-wrapper">
            <img src={loader} alt="loading icon" />
        </div>
    )
};

export default Loader;