import { BudgetObject } from './../../utils/types';
import { createSlice } from '@reduxjs/toolkit';
import { editBudget, getEditBudget } from '../thunks/EditBudget';

export interface EditState {
    errors: string | null[]
    loading: boolean,
    success: boolean,
    editBudget: null | BudgetObject
};

const initialState: EditState = {
    errors: [],
    loading: false,
    success: false,
    editBudget: null
};

export const editSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setSuccess(state, action) {
            state.success = action.payload;
        },
        resetEdit: () => initialState
    },
    extraReducers: builder => {
        builder
        .addCase(editBudget.pending, (state) => {
            state.loading = true;
        })
        .addCase(editBudget.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        })
        .addCase(editBudget.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
        .addCase(getEditBudget.pending, (state) => {
            state.loading = true;
        })
        .addCase(getEditBudget.fulfilled, (state, action) => {
            state.loading = false;
            state.editBudget = action.payload;
        })
        .addCase(getEditBudget.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
    }
});

export const { setLoading, setSuccess, resetEdit } = editSlice.actions;

export default editSlice.reducer;