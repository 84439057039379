import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs } from "../../api/firestore";

export const getBudgets = createAsyncThunk<any, object, { rejectValue: any }>(
    "dashboard/getBudgets",

    async (data: any, { rejectWithValue }) => {
        console.log('in getBudgets');

        try {
            const docs = await getDocs('budget', data.filters, data.sort, data.limit);
            console.log('docs', docs);
            return docs;
        } catch (error) {
            console.log('getBudgets ERROR', error);

            return rejectWithValue(error);
        }
    }
);
