import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDoc } from "../../api/firestore";
import { getSingleDoc } from "../../api/firestore";

export const editBudget = createAsyncThunk<any, object, { rejectValue: any }>(
    "edit/editBudget",

    async (data: any, { rejectWithValue }) => {
        try {
            const updatedDoc = await updateDoc('budget', data);
            console.log('updatedDoc', updatedDoc);
            return updatedDoc;
        } catch (error) {
            console.log('editBudget ERROR', error);
            return rejectWithValue(error);
        }
    }
);

export const getEditBudget = createAsyncThunk<any, string, { rejectValue: any }>(
    "budget/getEditBudget",

    async (data: any, { rejectWithValue }) => {
        console.log('in getBudgets data', data);

        try {
            const doc = await getSingleDoc('budget', data);
            console.log('doc', doc);
            return doc;
        } catch (error) {
            console.log('getBudgets ERROR', error);

            return rejectWithValue(error);
        }
    }
);