import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { pieChartColors } from "../../utils/constants";

interface piElememt {
  value: string,
  data: string
};
console.log('piechart check', window.innerWidth);


const PieGraph = (props:any) => {
      return (
        <ResponsiveContainer
          className="pie-chart-wrapper"
          width={window.innerWidth > 991 ? '100%' : 300}
          height={window.innerWidth > 991 ? '100%' : 300}
        >
          <PieChart
            width={window.innerWidth > 991 ? 400 : 300}
            height={window.innerWidth > 991 ? 400 : 300}
          >
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={props.data}
              startAngle={-270}
              endAngle={-630}
              cx="50%"
              cy="50%"
              outerRadius={ window.innerWidth > 991 ? 180 : 150}
              fill="#8884d8"
            >
              {props.data.map((entry: piElememt, index: number) => {
                console.log("entry", entry);
                return (
                  <Cell fill={pieChartColors[index % pieChartColors.length]} />
                );
              })}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      );
};

export default PieGraph;
