import { useEffect, useState } from "react";
import { BudgetItem } from "../../utils/types";
import BudgetElement from "../../components/BudgetElement/BudgetElement";
import Input from "../../components/Input/Input";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setLoading, setSuccess } from "../../store/slices/EditBudget";
import { createBudget } from "../../store/thunks/Add";
import Loader from "../../components/Loader/Loaders";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "../EditBudget/EditBudget.scss";
import { clearAddBudget } from "../../store/slices/Dashboard";

const AddNew = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [budgetTitle, setBudgetTitle] = useState("");
  const [isFavorite, setIsFavorite] = useState(true);
  const [showExpenses, setShowExpenses] = useState(true);
  const [showIncomes, setShowIncomes] = useState(true);

  const [budgetArray, setBudgetArray] = useState<BudgetItem[]>([]);
  const [expenseArray, setExpenseArray] = useState<BudgetItem[]>([]);
  const [incomeArray, setIncomeArray] = useState<BudgetItem[]>([]);

  const user = useAppSelector((state) => state.auth.user);
  const success = useAppSelector((state) => state.add.success);
  const loading = useAppSelector((state) => state.add.loading);

  const expenseItem: BudgetItem = {
    type: "Expense",
    category: "",
    label: "",
    dueDate: 1,
    frequency: "Monthly",
    amount: 0,
  };

  const incomeItem: BudgetItem = {
    type: "Income",
    category: "",
    label: "",
    dueDate: 1,
    frequency: "Monthly",
    amount: 0,
  };

  useEffect(() => {
    // setBudgetArray([{ ...budgetItem }]);
    setIncomeArray([{ ...incomeItem }]);
    setExpenseArray([{ ...expenseItem }]);
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(setSuccess(false));
      navigate("/");
    }
  }, [success]);

  // function to add a new section to a budget
  // const addSection = () => {
  //   setBudgetArray([...budgetArray, { ...budgetItem }]);
  // };

  // function to add a new section to a expenses
  const addExpenseSection = () => {
    setExpenseArray([...expenseArray, { ...expenseItem }]);
  };

  // function to add a new section to a incomes
  const addIncomeSection = () => {
    setIncomeArray([...incomeArray, { ...incomeItem }]);
  };

  const incomeSectionChanged = (
    type: string,
    val: any,
    multiple: number,
    index: number
  ) => {
    if (type === "dueDate" && (val > 31 || val < 1)) {
      return;
    }
    let temp: any = { ...incomeArray[index] };
    if (multiple) {
      if (multiple === 2) {
        if (Array.isArray(temp[type as string])) {
          temp[type as string] = [
            temp[type as string][temp[type as string].length - 1],
            val,
          ];
        } else {
          temp[type as string] = [val];
        }
      }
    } else {
      if (type === "frequency" && val === "annually") {
        temp[type as string] = val;
        temp["dueDate"] = ["1", 1];
      } else if (type === "frequency") {
        temp["dueDate"] = 1;
      }
      if (temp["frequency"] == "annually" && type == "dueDate") {
        if (typeof val === "number") {
          temp[type as string] = [temp[type as string][0], val];
        } else {
          temp[type as string] = [val, temp[type as string][1]];
        }
      } else {
        temp[type as string] = val;
      }
    }
    let tempArray = [...incomeArray];
    tempArray[index] = temp;
    setIncomeArray(tempArray);
  };

  const expenseSectionChanged = (
    type: string,
    val: any,
    multiple: number,
    index: number
  ) => {
    if (type === "dueDate" && (val > 31 || val < 1)) {
      return;
    }
    let temp: any = { ...expenseArray[index] };
    if (multiple) {
      if (multiple === 2) {
        if (Array.isArray(temp[type as string])) {
          temp[type as string] = [
            temp[type as string][temp[type as string].length - 1],
            val,
          ];
        } else {
          temp[type as string] = [val];
        }
      }
    } else {
      if (type === "frequency" && val === "annually") {
        temp[type as string] = val;
        temp["dueDate"] = ["1", 1];
      } else if (type === "frequency") {
        temp["dueDate"] = 1;
      }
      if (temp["frequency"] == "annually" && type == "dueDate") {
        if (typeof val === "number") {
          temp[type as string] = [temp[type as string][0], val];
        } else {
          temp[type as string] = [val, temp[type as string][1]];
        }
      } else {
        temp[type as string] = val;
      }
    }
    let tempArray = [...expenseArray];
    tempArray[index] = temp;
    setExpenseArray(tempArray);
  };

  // const sectionChanged = (
  //   type: string,
  //   val: any,
  //   multiple: number,
  //   index: number
  // ) => {
  //   if (type === "dueDate" && (val > 31 || val < 1)) {
  //     return;
  //   }
  //   let temp: any = { ...budgetArray[index] };
  //   if (multiple) {
  //     if (multiple === 2) {
  //       if (Array.isArray(temp[type as string])) {
  //         temp[type as string] = [
  //           temp[type as string][temp[type as string].length - 1],
  //           val,
  //         ];
  //       } else {
  //         temp[type as string] = [val];
  //       }
  //     }
  //   } else {
  //     if (type === "frequency" && val === "annually") {
  //       temp[type as string] = val;
  //       temp["dueDate"] = ["1", 1];
  //     } else if (type === "frequency") {
  //       temp["dueDate"] = 1;
  //     }
  //     if (temp["frequency"] == "annually" && type == "dueDate") {
  //       if (typeof val === "number") {
  //         temp[type as string] = [temp[type as string][0], val];
  //       } else {
  //         temp[type as string] = [val, temp[type as string][1]];
  //       }
  //     } else {
  //       temp[type as string] = val;
  //     }
  //   }
  //   let tempArray = [...budgetArray];
  //   tempArray[index] = temp;
  //   setBudgetArray(tempArray);
  // };

  const deleteButtonClicked = (index: number, type: string) => {
    if (type === "income") {
      let tempArray = [...incomeArray];
      tempArray.splice(index, 1);
      setIncomeArray(tempArray);
    } else {
      let tempArray = [...expenseArray];
      tempArray.splice(index, 1);
      setExpenseArray(tempArray);
    }
  };

  const saveBudgetClicked = () => {
    dispatch(setLoading(true));
    let budgetObj = {
      title: budgetTitle,
      created_at: new Date(),
      expenses: [...expenseArray],
      income: [...incomeArray],
      user_id: user.uid,
      favorite: isFavorite,
    };
    console.log("budgetObj", budgetObj);
    dispatch(clearAddBudget());
    dispatch(createBudget({ ...budgetObj }));
  };

  return (
    <div className="edit-wrapper">
      {loading ? (
        <Loader height="80vh" width="100%" />
      ) : (
        <>
          <div className="edit-header edit-form-heading">
            <h1>{budgetTitle.length ? budgetTitle : "Untitled Budget"}</h1>
          </div>
          <div className="edit-form">
            <div className="budget-form-fieldset">
              <div className="form-title">
                <p>Enter a title for your budget</p>
                <Input
                  type="text"
                  value={budgetTitle}
                  onChange={(e: string) => setBudgetTitle(e)}
                />
              </div>
              <div className="form-favorite">
                {/* <p>Mark as favorite?</p>
                <input type="checkbox" /> */}
                <p>Mark as favorite?</p>
                <Input
                  type="checkbox"
                  checked={isFavorite}
                  onChange={() => setIsFavorite(!isFavorite)}
                />
              </div>
            </div>
            <div className="income-section">
              <div
                className="section-header"
                onClick={() => setShowIncomes(!showIncomes)}
              >
                <p>Income</p>
                {showIncomes ? (
                  <FontAwesomeIcon
                    className="dropdown-input-caret"
                    icon={faAngleDown}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="dropdown-input-caret"
                    icon={faAngleRight}
                  />
                )}
              </div>
              <div
                className={`income-section-holder ${
                  showIncomes ? "show-section" : ""
                }`}
              >
                {incomeArray.length
                  ? incomeArray.map((item: BudgetItem, index: number) => {
                      return (
                        <div className="">
                          <BudgetElement
                            type={item.type}
                            frequency={item.frequency}
                            amount={item.amount}
                            dueDate={item.dueDate}
                            category={item.category}
                            label={item.label}
                            labelPosition="top"
                            onChange={(
                              type: string,
                              val: any,
                              multiple: number = 0
                            ) =>
                              incomeSectionChanged(type, val, multiple, index)
                            }
                            onDelete={() =>
                              deleteButtonClicked(index, "income")
                            }
                          />
                        </div>
                      );
                    })
                  : ""}
                <div className="edit-form-add-btn-wrapper">
                  <div onClick={addIncomeSection} className="edit-form-add-btn">
                    <p>
                      <FontAwesomeIcon className="trash-icon" icon={faPlus} />{" "}
                      New Income
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="expense-section">
              <div
                className="section-header"
                onClick={() => setShowExpenses(!showExpenses)}
              >
                <p>Expenses</p>
                {showExpenses ? (
                  <FontAwesomeIcon
                    className="dropdown-input-caret"
                    icon={faAngleDown}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="dropdown-input-caret"
                    icon={faAngleRight}
                  />
                )}
              </div>{" "}
              <div
                className={`expense-section-holder ${
                  showExpenses ? "show-section" : ""
                }`}
              >
                {expenseArray.length
                  ? expenseArray.map((item: BudgetItem, index: number) => {
                      return (
                        <div>
                          <BudgetElement
                            type={item.type}
                            frequency={item.frequency}
                            amount={item.amount}
                            dueDate={item.dueDate}
                            category={item.category}
                            label={item.label}
                            labelPosition="top"
                            onChange={(
                              type: string,
                              val: any,
                              multiple: number = 0
                            ) =>
                              expenseSectionChanged(type, val, multiple, index)
                            }
                            onDelete={() =>
                              deleteButtonClicked(index, "expense")
                            }
                          />
                        </div>
                      );
                    })
                  : ""}
                <div className="edit-form-add-btn-wrapper">
                  <div
                    onClick={addExpenseSection}
                    className="edit-form-add-btn"
                  >
                    <p>
                      <FontAwesomeIcon className="trash-icon" icon={faPlus} />{" "}
                      New Expense
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-btn-holder">
            <div className="btn cancel-btn" onClick={() => navigate(-1)}>
              <p>Cancel</p>
            </div>
            <div className="btn save-btn" onClick={saveBudgetClicked}>
              <p>Save</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddNew;
