import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";

import { filterItem, sortObj } from "../utils/types";

export const queryBuilder = async (type:string, filters?:filterItem[], sort?:sortObj, limitData?:number) => {
    
    let filtersArray:any[] = [];
    if (filters) {
        for (let i = 0; i < filters.length; i++) {
            const element: filterItem = filters[i];
            filtersArray = [...filtersArray, where(element.field, element.operator, element.value)]
        }
    }
    if (sort) {

    }
    if (limitData) {

    }
    limitData = limitData ? limitData : -1;
    // let sortOption = sort ? orderBy(sort.value, sort.direction) : null;
    const q = query(collection(db, type), ...filtersArray);
    const querySnapshot = await getDocs(q);
    let results: any[] = [];
    querySnapshot.forEach((doc) => {
        results = [...results, { id: doc.id, ...doc.data() }];
        console.log(doc.id, ' => ', doc.data());
    });
    return results;
};