import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDoc } from "../../api/firestore";

export const createBudget = createAsyncThunk<any, object, { rejectValue: any }>(
    "add/createBudget",

    async (data: any, { rejectWithValue }) => {
        
        try {
            const createdDoc = await createDoc('budget', data);
            console.log('createdDoc', createdDoc);
            return createdDoc;
        } catch (error) {
            console.log('createBudget ERROR', error);
            
            return rejectWithValue(error);
        }
    }
);
